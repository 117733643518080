import { lazy, Suspense } from 'react'
import queryString from 'query-string'
import { useNavigate } from 'react-router-dom'
import { MainSearchField, Share } from '../common-ui'
import { ISearchQuery } from './interfaces'
import MoreDropdown from './MoreDropdown'
import PropertyDropdown from './PropertyDropdown'
import SecondaryDropdown from './SecondaryDropdown'
import { listingsSearch } from '../../store/search/actions'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { updateQuery } from '../common-ui/helpers'
import ModalVersion from '../Modal/ModalVersion'
const ModalShare = lazy(() => import('../Modal/ModalShare'))
const ModalEmail = lazy(() => import('../Modal/ModalEmail/ModalEmail'))

const FilterPanel = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const query = queryString.parse(window.location.search) as ISearchQuery
  const isModalOpen = useAppSelector((state) => state.search.isModalShareOpen)
  const isModalEmailOpen = useAppSelector((state) => state.search.isModalOpen)
  const { listingTypeIds, entityType, isDealer } = query
  return (
    <>
      <Suspense fallback={null}>{isModalEmailOpen && <ModalEmail />}</Suspense>
      <Suspense fallback={null}>{isModalOpen && <ModalShare />}</Suspense>
      <ModalVersion />
      <div className="filterPanel__wrapper">
        <div className="filterPanel__searchWrapper">
          <MainSearchField />
          {window.innerWidth >= 768 ? null : <Share />}
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <PropertyDropdown
            updateQuery={(updates) =>
              updateQuery({ updates, navigate, dispatch, listingsSearch })
            }
            listingTypeIds={listingTypeIds}
            entityType={entityType}
            isDealer={isDealer === 'true'}
          />
          <SecondaryDropdown
            updateQuery={(updates) =>
              updateQuery({ updates, navigate, dispatch, listingsSearch })
            }
            listingTypeIds={listingTypeIds}
            entityType={entityType}
            isDealer={isDealer === 'true'}
            currentQuery={query}
          />
          <MoreDropdown
            updateQuery={(updates) =>
              updateQuery({ updates, navigate, dispatch, listingsSearch })
            }
            listingTypeIds={listingTypeIds}
            entityType={entityType}
            isDealer={isDealer === 'true'}
            query={query}
          />
          {window.innerWidth < 768 ? null : <Share />}
        </div>
      </div>
    </>
  )
}

export default FilterPanel
